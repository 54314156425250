import BasePlayScene from '../BaseFinder/PlayScene'

export default class PlayScene extends BasePlayScene {
  preload () {
    this.load.image('bg', '/static/games/game7/bg.jpg');

    if (this.sys.game.device.os.desktop){
      this.load.spritesheet('bg_anim', '/static/games/game7/bg_anim.png',{ frameWidth: 1600, frameHeight: 900 });
    } else {
      this.load.image('bg_anim', '/static/games/game7/bg_noanim.png');
    }
    
    this.load.image('dropzone', '/static/games/game7/dropzone.png');
    this.load.audio('bg_audio', '/static/games/game7/bg.mp3');

    this.corrects = [
      { name:'1.png', x:43, y:615, drop_x:593, drop_y:785, width: Math.floor(785/3), height: 102, drop_scale: 0.68, title:"ชุดความรู้แม่และเด็ก" },
      { name:'2.png', x:223, y:394, drop_x:770, drop_y:768, width: Math.floor(490/3), height: 103, drop_scale: 1, title:"ชุดความรู้การใช้ยากับการให้นมบุตร" },
      { name:'3.png', x:1336, y:220, drop_x:395, drop_y:789, width: Math.floor(308/3), height: 73, drop_scale: 0.7, title:"เบอร์โทรสายตรวจความรุนแรง" },
      { name:'4.png', x:1361, y:436, drop_x:940, drop_y:765, width: Math.floor(725/3), height: 197, drop_scale: 0.5, title:"ชุดยาสะอาด" },
      { name:'5.png', x:837, y:352, drop_x:491, drop_y:777, width: Math.floor(290/3), height: 76, drop_scale: 1, title:"ชุดความรู้เรื่องอนามัยเจริญพันธุ์" }
    ]

    this.wrongs = [
      { name:'6.png', x:1187, y:617, width: Math.floor(1125/3), height: 138 },
      { name:'7.png', x:723, y:646, width: Math.floor(415/3), height: 78 },
      { name:'8.png', x:11, y:559, width: Math.floor(471/3), height: 75 },
      { name:'9.png', x:3, y:694, width: Math.floor(315/3), height: 66 },
      { name:'10.png', x:453, y:282, width: Math.floor(155/3), height: 33 },
      { name:'11.png', x:266, y:573, width: Math.floor(310/3), height: 78 },
      { name:'12.png', x:898, y:195, width: Math.floor(278/3), height: 104 },
      { name:'13.png', x:428, y:490, width: Math.floor(290/3), height: 93 },
    ]

    this.corrects.forEach((item,index)=>{
      this.load.spritesheet('correct-'+index, "/static/games/game7/objects/correct/"+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    this.wrongs.forEach((item,index)=>{
      this.load.spritesheet('wrong-'+index, "/static/games/game7/objects/wrong/"+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    super.preload();
  }

  init(data) {
    this.ended = false
    this.timer = 60000
    this.score = 0
    this.total_score = 5
    this.dropzone_w = 750
  }
}
